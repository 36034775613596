body {
  letter-spacing: normal;
}

.loading-indicator:before {
  content: "";
  background: #000000cc;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loading-indicator:after {
  content: "Loading";
  position: fixed;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 1001;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
}

.summary-container {
  display: grid;
  grid-template-rows: max-content;
  grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
  grid-gap: 1.5rem;
}

.summary {
  display: grid;
  /*justify-content: center;*/
  /*align-items: center;*/
  padding: 20px;
}

.DateInput {
  width: auto;
}

.comment-btn {
    padding: .4rem;
    color: #fff;
    background: #007bff;
    margin-left: 5rem;
    border-radius: 3px;
    min-width: 5rem;
    cursor: pointer;
}

.cancel-btn {
    padding: .4rem;
    color:#007bff;
    background: transparent;
    border: 1px solid #007bff;
    border-radius: 3px;
    min-width: 5rem;
}
.cancel-btn:hover {
    background: transparent;
    color:#007bff;
}
.modal-body {
  max-height: 80vh;
  overflow-y: auto;
}