

.DateRangePickerInput__withBorder {
    border-radius: 2px;
    border:none;
    border-right: 1px solid rgba(0,0,0,0.15);
    border-top: 1px solid rgba(0,0,0,0.15);
    border-bottom: 1px solid rgba(0,0,0,0.15);
}

.DateInput_input {
    font-weight: 200;
    font-size: 14px;
    line-height: 24px;
    color: #484848;
    background-color: #fff;
    width: 100%;
    padding: 4px 11px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: 0;
    border-right: 0;
    border-bottom: 2px solid transparent;
    border-left: 0;
    border-radius: 0;
}